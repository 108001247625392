import {
  GoogleMap,
  InfoWindow,
  Marker,
  DistanceMatrixService,
} from "@react-google-maps/api";
import React, { useEffect, useState, useRef } from "react";
import MarcerIcon from "../assets/marker.png";
import { CSVLink } from "react-csv";
import Spinner from "../Spinner";
// import { Circles } from "react-loader-spinner";

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  enableEventPropagation: false,
  clickableLabels: false,
  isableDoubleClickZoom: false,
  clickableIcons: false,
  disableDoubleClickZoom: false,
};

function Map(props) {
  const [showingInfoWindow, setShowingInfoWindow] = useState();
  const [selectedPlace, setSelectedPlace] = useState({});
  const [origin, setOrigin] = useState({});
  const [destination, setDestination] = useState({});
  const [destinations, setDestinations] = useState();
  const [totalDistance, setTotaleDistance] = useState();
  const [totalDuration, setTotalDuration] = useState();
  const [deliveryFees, setDeliveryFees] = useState();
  const [loading, setLoading] = useState(true);
  const [isDownloaded, setIsDownloading] = useState(false);
  const [isWithinBoundries, setIsWithinBoundries] = useState(false);
  const csvLink = useRef();

  const headers = [
    { label: "Approximate Destination", key: "destination" },
    { label: "Approximate Distance", key: "distance" },
    { label: "Approximate Duration", key: "duration" },
    { label: "Approximate Delivery fees", key: "deliveryFee" },
  ];

  const currentPosition = {
    lat: Number(props.currentPosition.lat) || 0,
    lng: Number(props.currentPosition.lng) || 0,
  };

  const [marker, setMarker] = useState(currentPosition);
  const [center, setCenter] = useState(currentPosition);
  const geocoder = new window.google.maps.Geocoder();

  useEffect(() => {
    setCenter(marker);
  }, [marker]);

  async function getDistanceMatrix(origin, destination) {
    // setOrigin(origin);
    // setDestination(destination);
    let service = new window.google.maps.DistanceMatrixService();

    await service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
      },
      (res) => {
        setTotaleDistance(res.rows[0].elements[0].distance.value);
        setTotaleDistance(res.rows[0].elements[0].distance.text);
        setTotalDuration(res.rows[0].elements[0].duration.text);

        const totalDistanceValue = res.rows[0].elements[0].distance.value;
        let additionalMeterPrice = 0;
        if (totalDistanceValue > props.baseDeliveryMeters) {
          const additionalMeterUnits =
            (totalDistanceValue - props.baseDeliveryMeters) /
            props.extraDeliveryUnitMeters;
          additionalMeterPrice =
            additionalMeterUnits * props.extraDeliveryUnitFee;
        }
        let deliveryFee = props.baseDeliveryFee + additionalMeterPrice;
        if (deliveryFee > (props.maxDeliveryFee && props.maxDeliveryFee)) {
          deliveryFee = props.maxDeliveryFee || deliveryFee;
        }
        setDeliveryFees(parseFloat(deliveryFee.toFixed(2)));
      }
    );
  }

  const onMarkerClick = async (index, area) => {
    const distanceMatrix = await getDistanceMatrix(marker, area.position);
    setShowingInfoWindow(index);
    setSelectedPlace(marker);
    inside([marker.lat, marker.lng], props.boundries);
    calculateDeliveryFees();
  };

  const calculateDeliveryFees = () => {
    setLoading(false);
    setOrigin();
    setDestination();
  };

  function inside(point, vs) {
    var x = point[0],
      y = point[1];

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0],
        yi = vs[i][1];
      var xj = vs[j][0],
        yj = vs[j][1];

      var intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    setIsWithinBoundries(inside);
    return inside;
  }

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const exportToCSV = async () => {
    setIsDownloading(true);

    //get all the areas(destinations)
    let places = [];
    let responses = [];
    props.areas.forEach(function (area) {
      places.push(area.position);
    });

    // split to chuncks of 20s - matrix api's max is 25 origin/destination
    let chuncks = [];
    let service = new window.google.maps.DistanceMatrixService();
    var i,
      j,
      temporary,
      chunk = 10;
    for (i = 0, j = places.length; i < j; i += chunk) {
      temporary = places.slice(i, i + chunk);
      chuncks.push(temporary);
    }

    for (let index = 0; index < chuncks.length; index++) {
      const chunck = chuncks[index];
      await service.getDistanceMatrix(
        {
          origins: [marker],
          destinations: chunck,
          travelMode: "DRIVING",
        },
        (res) => {
          responses.push(res);
        }
      );
      await delay(750);
    }

    //format the data and flatten the chuncks
    let destinations = [];
    for (let index = 0; index < responses.length; index++) {
      const response = responses[index];
      for (let i = 0; i < response.destinationAddresses.length; i++) {
        let destObj = {};
        let key = i;
        if (index > 0) {
          key = index * 10 + i;
        }
        const element = props.areas[key].name;
        destObj.destination = element;
        destObj.origin = response.originAddresses[0];
        destObj.matrix = response.rows[0].elements[i];
        destObj.deliveryFee = 0;
        destinations.push(destObj);
      }
    }

    //calculate the price for each destination
    destinations.forEach((dest) => {
      let des = dest;
      let additionalMeterPrice = 0;
      let totalDistanceValue =
        des.matrix && des.matrix.distance && des.matrix.distance.value;
      if (totalDistanceValue > props.baseDeliveryMeters) {
        const additionalMeterUnits =
          (totalDistanceValue - props.baseDeliveryMeters) /
          props.extraDeliveryUnitMeters;
        additionalMeterPrice =
          additionalMeterUnits * props.extraDeliveryUnitFee;
      }
      let deliveryFee = props.baseDeliveryFee + additionalMeterPrice;
      if (deliveryFee > (props.maxDeliveryFee && props.maxDeliveryFee)) {
        deliveryFee = props.maxDeliveryFee || deliveryFee;
      }
      des.sortField = totalDistanceValue;
      des.deliveryFee = `${parseFloat(deliveryFee.toFixed(2))} ${
        props.currency
      }`;
      des.duration =
        des.matrix && des.matrix.distance && des.matrix.duration.text;
      des.distance =
        des.matrix && des.matrix.distance && des.matrix.distance.text;
    });

    destinations.sort(function (a, b) {
      return a.sortField - b.sortField;
    });

    setDestinations(destinations);
    csvLink.current && csvLink.current.link.click();
    setIsDownloading(false);
  };

  return (
    <div style={{ flex: 1, position: "relative", width: "100%" }}>
      {
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            width: "75vw",
            margin: "auto",
          }}
        >
          <button
            onClick={() => {
              exportToCSV();
            }}
            style={{
              display: "flex",
              padding: "14px 18px",
              margin: "10px",
              background: "#40e0d0",
              border: "grey",
              borderRadius: "10px",
              color: "white",
              fontWeight: 700,
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            Export
            {isDownloaded ? (
              <div style={{ paddingLeft: "10px" }}>
                {/* <Circles arialLabel="loading-indicator" color="white" height={20} width={20} />
                 */}
                <Spinner style={{ color: "white" }} />
              </div>
            ) : null}
          </button>
          {destinations && (
            <CSVLink
              data={destinations && destinations}
              headers={headers}
              filename={
                destinations && destinations[0] && destinations[0].origin
              }
              ref={csvLink}
              target="_blank"
            />
          )}
        </div>
      }
      <GoogleMap
        zoom={props.zoom || 10}
        center={center}
        mapContainerStyle={{
          height: "75vh",
          width: "75vw",
          margin: "auto",
          border: "2px solid grey",
          borderRadius: "15px",
        }}
        onClick={(e) => {
          const geolocation = { lat: e.latLng.lat(), lng: e.latLng.lng() };
          inside([e.latLng.lat(), e.latLng.lng()], props.boundries);
          if (isWithinBoundries) {
            geocoder.geocode({}, (results, status) => {
              if (results) {
                const result = results[0];
                if (result) {
                  setMarker({
                    lat:
                      result.geometry &&
                      result.geometry.location &&
                      result.geometry.location.lat(),
                    lng:
                      result.geometry &&
                      result.geometry.location &&
                      result.geometry.location.lng(),
                  });
                } else {
                  console.log("data not found for the selected location");
                }
              }
            });
            setMarker(geolocation);
          } else {
            e.stop();
            e.cancelBubble = true;
            if (e.stopPropagation) {
              e.stopPropagation();
            }
          }
        }}
        options={options}
      >
        {props.areas.map((marker, index) => (
          <Marker
            key={index} // Need to be unique
            options={{ icon: MarcerIcon, scaledSize: 0.5 }}
            onClick={() => onMarkerClick(index, marker)}
            name={marker.name}
            position={marker.position}
          >
            {(showingInfoWindow == index) & !loading && (
              <InfoWindow
                onCloseClick={() => {
                  setOrigin();
                  setDestination();
                  setLoading(true);
                }}
              >
                <div>
                  <div>{marker.name}</div>
                  <div>{totalDistance}</div>
                  <div>{totalDuration}</div>
                  <div>{`${deliveryFees} ${props.currency}`}</div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
        {marker && <Marker setClickableIcons={false} position={marker} />}
      </GoogleMap>
    </div>
  );
}

export default Map;
