import "./App.css";
import { AUTH, DATABASE } from "./config/fbConfig";
import { EMAIL, PASSWORD } from "./config/authConfig";
import { useEffect, useState } from "react";
import { LoadScript } from "@react-google-maps/api";
import Map from "./MapContainer";
import Spinner from "./Spinner";

function App() {
  const [zoom, setZoom] = useState(11);
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [boundries, setBoundries] = useState([]);
  const [regionConfig, setRegionConfig] = useState({});
  const [maxDeliveryFee, setMaxDeliveryFee] = useState(0);
  const [baseDeliveryFee, setBaseDeliveryFee] = useState(0);
  const [baseDeliveryMeters, setBaseDeliveryMeters] = useState(0);
  const [extraDeliveryUnitFee, setExtraDeliveryUnitFee] = useState(0);
  const [extraDeliveryUnitMeters, setExtraDeliveryUnitMeters] = useState(0);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const latitude = params.get("latitude");
  const longitude = params.get("longitude");

  const handleSignInRequest = () => {
    return new Promise(async function (resolve, reject) {
      AUTH.signInWithEmailAndPassword(EMAIL, PASSWORD)
        .then((response) => {
          resolve({ response });
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  const getAreasAndTheirGeopoints = async () => {
    await getRegionConfig();

    await getRegionAreas();

    await getETAPriceMatrix();

    await getRegionBoundries();

    setLoading(false);
  };

  const getRegionConfig = async () => {
    await DATABASE.collection("config")
      .doc("region_config")
      .get()
      .then((data) => {
        const document = data.data();
        setRegionConfig(document);
      });
  };

  const getRegionAreas = async () => {
    let areas = [];
    await DATABASE.collection("config")
      .doc("areas_geopoints")
      .get()
      .then((data) => {
        const document = data.data().areas;
        document.map((area) => {
          areas.push({
            name: area.name,
            position: {
              lat: area.geopoint.latitude,
              lng: area.geopoint.longitude,
            },
            googleMapOrigin: area.googleMapOrigin,
          });
        });
      });
    setAreas(areas);
  };

  const getETAPriceMatrix = async () => {
    await DATABASE.collection("config")
      .doc("eta_price")
      .get()
      .then((data) => {
        const document = data.data().courierConfig;
        setMaxDeliveryFee(document.maxDeliveryFee);
        setBaseDeliveryFee(document.baseDeliveryFee);
        setBaseDeliveryMeters(document.baseDeliveryMeters);
        setExtraDeliveryUnitFee(document.extraDeliveryUnitFee);
        setExtraDeliveryUnitMeters(document.extraDeliveryUnitMeters);
      });
  };

  const getRegionBoundries = async () => {
    await DATABASE.collection("config")
      .doc("business_config")
      .collection("regionsGeoPolygons")
      .doc("kuwait")
      .get()
      .then((data) => {
        const points = data.data().points;
        let boundries = [];
        points.forEach((point) => {
          boundries.push([point.latitude, point.longitude]);
        });
        setBoundries(boundries);
      });
  };

  useEffect(() => {
    handleSignInRequest();
    getAreasAndTheirGeopoints();
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner
            style={{ height: "5rem", width: "5rem", color: "#40e0d0" }}
          />
        </div>
      ) : (
        <div>
          <h2>{`Please select your pickup location to view Mashkor delivery fees from all over ${
            (regionConfig && regionConfig.country) || ""
          }${
            regionConfig && regionConfig.country === "Bahrain"
              ? "(Non inclusive of VAT amount)"
              : ""
          }.`}</h2>
          <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}>
            <Map
              currentPosition={{
                lat: latitude || regionConfig.centerPoint.latitude,
                lng: longitude || regionConfig.centerPoint.longitude,
              }}
              areas={areas}
              zoom={zoom}
              currency={regionConfig.currency}
              maxDeliveryFee={maxDeliveryFee}
              baseDeliveryFee={baseDeliveryFee}
              baseDeliveryMeters={baseDeliveryMeters}
              extraDeliveryUnitFee={extraDeliveryUnitFee}
              extraDeliveryUnitMeters={extraDeliveryUnitMeters}
              setZoom={setZoom}
              boundries={boundries}
            />
          </LoadScript>
        </div>
      )}
    </div>
  );
}

export default App;
