// Firebase config
import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";


firebase.initializeApp({
  projectId: process.env.REACT_APP_PROJECT_ID,
  apiKey: process.env.REACT_APP_API_KEY,
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
  appId: process.env.REACT_APP_APP_ID,
  authDomain: `${process.env.REACT_APP_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_PROJECT_ID}.firebaseio.com`,
  storageBucket: `${process.env.REACT_APP_PROJECT_ID}.appspot.com`,
});

export const AUTH = firebase.auth();
export const DATABASE = firebase.firestore();
export const STORAGE= firebase.storage();
export const FUNCTIONS = firebase.functions();
export default firebase;
